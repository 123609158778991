import styled from "styled-components"
import bgPlayers from "../../../images/static/bg-players.jpg"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 4rem 5rem;
  flex-direction: column;

  background-image: url(${bgPlayers});

  h2 {
    max-width: 1036px;
    width: 100%;
    font-size: 3.2rem;
    font-weight: bold;
    font-style: normal;
    line-height: 1.59;
    text-align: center;
    color: #ffffff;
    hr {
      margin: 33px auto;
      width: 120px;
      height: 8px;
      border-radius: 100px;
      background-color: #ffffff;
    }
  }
  ${media.lessThan("900px")`
    opacity: 0.75;
    background-image: linear-gradient(to bottom, #00b1b1 0%, #002a6a 100%);
      h2{
        display: none;
      }
  `}
  ${media.lessThan("900px")`
    min-height: 1300px;
  `}
  ${media.lessThan("600px")`
    min-height: 1500px;
  `}
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  flex: 1;
  padding: 0rem 20rem;
  width: 100%;
  ${media.lessThan("1300px")`
      padding: 0rem 0rem;
  `}

  h3 {
    max-width: 440px;
    width: 100%;
    font-size: 4rem;
    font-weight: normal;
    line-height: 1.38;
    text-align: center;
    color: #ffffff;
    b {
      font-weight: bold;
    }
    hr {
      margin: 33px auto;
      width: 120px;
      height: 8px;
      border-radius: 100px;
      background-color: #ffffff;
    }
  }
  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 25px;

      img {
        height: 110px;
      }

      p {
        margin: 45px 0 0;
        font-size: 2.2rem;
        font-weight: normal;
        letter-spacing: 2.3px;
        text-align: left;
        color: #ffffff;
        text-align: center;

        b {
          font-size: 4.6rem;
          font-weight: bold;
          line-height: 2;
        }
      }
    }
    ${media.lessThan("900px")`
      flex-wrap: wrap;
      justify-content: center;
    `}
  }
`
