import styled from "styled-components"
import { darken } from "polished"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10rem 4rem 5rem;
  flex-direction: column;
  background-image: linear-gradient(to bottom, #fbd000, #ff9e00);

  ${media.lessThan("600px")`
  `}

  h2 {
    max-width: 1120px;
    width: 100%;
    font-size: 2.4rem;
    font-weight: bold;
    line-height: 1.58;
    text-align: left;
    color: #fff;
    hr {
      margin: 34px 0;
      width: 120px;
      height: 8px;
      border-radius: 100px;
      background-color: #ffffff;
    }
  }
`
export const CustomScrollbar = styled.div`
  width: 100%;
  float: left;
  height: 450px;
  margin-left: 15px;
  margin-top: 40px;
  overflow-x: scroll;
  overflow-y: hidden;
  background: transparent;
  -webkit-overflow-scrolling: touch;

  ${media.lessThan("600px")`
    min-height: 450px;
  `}
  &::-webkit-scrollbar {
    height: 4px;
    border-radius: 50px;
    background-color: #ff9e00;
  }
  &::-webkit-scrollbar-thumb {
    cursor: pointer;
    background-color: ${darken(0.1, "#ff9e00")};
    transition: background 0.2s;
    &:hover {
      background-color: ${darken(0.2, "#ff9e00")};
    }
  }
  &::-webkit-scrollbar-track {
    background-color: #ff9e00;
  }
`
export const ListTimeline = styled.ul`
  list-style: none;
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding: 0 2rem;
`

export const TimelineItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  img {
    margin-bottom: 20px;
    height: 300px;
  }
  p {
    margin: auto;
    max-width: 220px;
    width: 100%;
    max-height: 202.6px;
    height: 100%;
    font-size: 1.4rem;
    line-height: 1.3;
    text-align: center;
    color: #ffffff;
  }
`
