import React, { useContext } from 'react';
import { FaPlay } from 'react-icons/fa';
import { LanguageContext } from '../../../context/LanguageContext';
import FormContact from '../../FormContact';
import { Container } from './styles';

export default function Contact({ formRef }) {
  const { idioma } = useContext(LanguageContext);

  var contact = ['Fale Conosco', 'Contact Us', 'Contáctenos'];
  var play = [
    'Dê um Play no seu negócio. A Hub Fintech está preparada para atender e entender suas necessidades',
    'Press Play in your business. Hub Fintech is prepared to meet and understand your needs',
    'Dar Play en su negocio. Hub Fintech está preparada para satisfacer y comprender sus necesidades',
  ];

  return (
    <Container ref={formRef}>
      <div>
        <h2>{contact[idioma]}</h2>
        <FaPlay size={32} color="#00b1b1" />
        <p>{play[idioma]}</p>
      </div>
      <FormContact />
    </Container>
  );
}
