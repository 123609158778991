import React, { useRef, useCallback } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import SomosHubComponent from "../components/Somos-Hub"
import Play from "../components/Somos-Hub/Play"
import Players from "../components/Somos-Hub/Players"
import Timeline from "../components/Somos-Hub/Timeline"
import Careers from "../components/Somos-Hub/Careers"
import Contact from "../components/Somos-Hub/Contact"
import Footer from "../components/Footer"
import Chat from "../components/Chat"

export default function SomosHub() {
  const formRef = useRef(null)

  const scrollToForm = () => {
    if (formRef)
      if (formRef.current)
        formRef.current.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <Layout>
      <SEO title="Somos hub" />
      <SomosHubComponent scrollToForm={() => scrollToForm()} />
      <Play />
      <Players />
      <Timeline />
      <Careers />
      <Contact formRef={formRef} />
      <Footer />
      <Chat />
    </Layout>
  )
}
