import styled from "styled-components"
import headerSomosHub from "../../images/static/header-somos-hub.png"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const PT = styled.img`
  display: flex;
  max-height: 20px;
  max-width: 30px;
  min-height: 20px;
  min-width: 30px;
  position: absolute;
  top: 15px;
  left: 15px
`
export const EN = styled.img`
  display: flex;
  max-height: 20px;
  max-width: 30px;
  min-height: 20px;
  min-width: 30px;
  position: absolute;
  top: 15px;
  left: 55px
`
export const ES = styled.img`
  display: flex;
  max-height: 20px;
  max-width: 30px;
  min-height: 20px;
  min-width: 30px;
  position: absolute;
  top: 15px;
  left: 95px
`

export const Header = styled.header`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 437px;
  background-image: url(${headerSomosHub});
  position: relative;
  overflow: hidden;

  ${media.lessThan("600px")`
    align-items: flex-end;
  `}
  & > a {
    position: absolute;
    left: 50px;
    top: 50px;
    img {
      width: 100%;
      height: 100px;
      max-width: 330px;
    }
    ${media.lessThan("600px")`
      left: auto;
      right: auto;
    `}
  }
  h2 {
    font-size: 5rem;
    font-weight: normal;
    text-align: center;
    color: #fff;
    padding: 0 15px;

    hr {
      width: 60px;
      height: 4px;
      border-radius: 100px;
      background-color: #ffffff;
      margin: 17px auto;
    }
  }
`
