import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import account from '../../../images/svg/account.svg';
import cards from '../../../images/svg/cards.svg';
import withdraw from '../../../images/svg/withdraw.svg';
import { Container, Content } from './styles';

export default function Players() {
  const { idioma } = useContext(LanguageContext);

  var title = [
    `<h2>Criamos soluções financeiras mais rentáveis aos nossos clientes, indo
              muito além da transação. Para isso, carregamos valores em nosso DNA que
              nos movem a alcançar grandes desafios.</h2>
              <hr />`,

    `<h2>We create more profitable financial solutions for our clients, going far beyond
              transaction. For this, we carry values in our DNA that move us to achieve great
              challenges.</h2>
              <hr />`,

    `<h2>Creamos soluciones financieras más rentables para nuestros clientes, yendo
              mucho más allá de la transacción. Para esto, llevamos valores en nuestro ADN
              que nos mueven a lograr grandes desafíos.</h2>
              <hr />`,
  ];

  var players = [
    `<h3><b>Players da Hub</b> Fintech</h3>
                <hr />`,

    `<h3><b>Hub Fintech</b> Players</h3>
                <hr />`,

    `<h3><b>Players de Hub</b> Fintech</h3>
                <hr />`,
  ];

  var accounts = [
    `<p>+ de <b>4 Milhões</b>
                  <br />
                  de contas ativas</p>`,

    `<p>+ <b>4 million</b>
                  <br />
                  active accounts</p>`,

    `<p>+ <b>4 millones</b>
                  <br />
                  de cuentas activas</p>`,
  ];

  var transactions = [
    `<p><b>+ 500</b> <br />
                      transações <br /> por segundo</p>`,

    `<p><b>+500 </b><br />
                      transactions <br /> per second</p>`,

    `<p><b>+500</b><br />
                      transacciones <br /> por segundo</p>`,
  ];

  var withdrawLabel = [
    `<p><b>+ 18 Mil</b> <br />
                  pontos para <br />
                  saque</p>`,

    `<p><b>+ 18 thousand</b><br />
                  points for <br />
                  withdrawal</p>`,

    `<p><b>+ 18 mil</b><br />
                  puntos para<br />
                  retiro</p>`,
  ];
  return (
    <Container>
      {Parser(title[idioma])}
      <Content>
        {Parser(players[idioma])}
        <ul>
          <li>
            <img src={cards} alt="cards" />
            {Parser(accounts[idioma])}
          </li>
          <li>
            <img src={account} alt="accounts" />
            {Parser(transactions[idioma])}
          </li>
          <li>
            <img src={withdraw} alt="withdraw" />
            {Parser(withdrawLabel[idioma])}
          </li>
        </ul>
      </Content>
    </Container>
  );
}
