import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import playSomosHub from '../../../images/static/play-somos-hub.png';
import colaboracao from '../../../images/svg/colaboracao.svg';
import intermediadores from '../../../images/svg/intermediadores.svg';
import servicos from '../../../images/svg/servicos.svg';
import { Container, Content } from './styles';

export default function Play() {
  const { idioma } = useContext(LanguageContext);

  var title = [
    '<h1>Preparado para dar um <b> Play no seu negócio</b></h1>',
    '<h1>Ready to press <b>Play in your Business</b></h1>',
    '<h1>Listo para dar <b>Play en su negocio</b></h1>',
  ];

  var baas = [
    `A Hub Fintech é líder em BaaS (Banking as a Service) no Brasil, entrega soluções White Label para o segmento B2B2C. 
              Atua com modelo único de negócios que combina soluções tecnológicas com serviços E2E de program manager, e disponibiliza 
              completo arcabouço regulatório.`,
    `Hub Fintech is the leader in BaaS (Banking as a Service) in Brazil, delivering White Label solutions for the B2B2C 
              segment. It operates with a unique business model that combines technological solutions with E2E program manager 
              services, and offers a complete regulatory framework`,
    `Hub Fintech es líder en BaaS (Banking as a Service) en Brasil, y ofrece soluciones White Label para el segmento B2B2C. 
              Opera con un modelo de negocio único que combina soluciones tecnológicas con servicios E2E de program manager y ofrece 
              un marco regulatorio completo`,
  ];

  var intermediaries = [
    `<p><b>Quando tiramos intermediadores do processo,</b> conseguimos
                        <b> reduzir custos</b> do portador e
                        <b> dividir a fatia da rentabilidade</b> com todos os parceiros e
                        usuários</p>`,

    `<p><b>When we take intermediaries out of the process,</b> we are able to 
                       <b>reduce carrier costs </b>and 
                       <b>share the profit</b> with all partners and 
                       users</p>`,

    `<p><b>Cuando eliminamos a los intermediarios del proceso,</b> podemos 
                      <b>reducir los costos</b> del portador y 
                      <b>dividir la participación en las ganancias</b> con todos los socios y
                      usuarios</p>`,
  ];

  var autonomy = [
    `<p><b>
                  Quando ampliamos os serviços financeiros, damos autonomia aos
                  usuários 
                  </b>
                  para utilizar da forma que achar mais conveniente de acordo com o
                  seu dia dia</p>`,

    `<p><b>When we expand financial services, we give users 
                  autonomy 
                  </b>
                  to use in whatever way they find most convenient according to their
                   daily lives</p>`,

    `<p><b>Cuando expandimos los servicios financieros, les damos a los 
                  usuarios </b>
                  autonomía para usar de la manera que les resulte más conveniente de acuerdo
                  con su vida diaria</p>`,
  ];
  var solutions = [
    `<p><b>Quando fornecemos informações aos nossos clientes,</b> damos
                     oportunidade de gerarem <b>melhores soluções</b> aos seus clientes
                     e oferecer <b>mecanismos de diferenciação</b></p>`,

    `<p><b>When we provide information to our customers,</b> we give them
                      the opportunity to generate <b>better solutions</b> for their customers
                       and offer <b>differentiation mechanisms</b></p>`,

    `<p><b>Cuando brindamos información a nuestros clientes,</b> les damos la 
                     oportunidad de generar <b>mejores soluciones</b> para sus clientes
                      y ofrecer <b>mecanismos de diferenciación</b></p>`,
  ];

  return (
    <Container>
      <img src={playSomosHub} alt="Somos hub fintech" />
      <Content>
        {Parser(title[idioma])}
        <hr />
        <p>{baas[idioma]}</p>
        <ul>
          <li>
            <img src={intermediadores} alt="sem intermediadores" />
            {Parser(intermediaries[idioma])}
          </li>
          <li>
            <img src={servicos} alt="damos autonomia aos usuarios" />
            {Parser(autonomy[idioma])}
          </li>
          <li>
            <img
              src={colaboracao}
              alt="damos a oportunidade para gerarem as melhores soluções"
            />
            {Parser(solutions[idioma])}
          </li>
        </ul>
      </Content>
    </Container>
  );
}
