import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  padding: 6rem 18rem;
  display: flex;
  overflow: hidden;
  ${media.lessThan("1400px")`
    & > img {
      display: none;
      }
  `}
  ${media.lessThan("1200px")`
    padding: 6rem 10rem;
  `}

  ${media.lessThan("900px")`
    padding: 6rem 5rem;
  `}

  ${media.lessThan("600px")`
    padding: 4rem 2rem;
  `}

  & > img {
    max-height: 500px;
  }
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;

  margin-left: 30px;
  ${media.lessThan("600px")`
    margin-left: 0px;
  `}

  h1 {
    max-width: 538px;
    width: 100%;
    font-size: 5rem;
    font-weight: 300;
    line-height: 1.32;
    text-align: left;
    color: #00b1b1;

    hr {
      width: 120px;
      height: 8px;
      border-radius: 100px;
      margin: 34px 0;
      background-color: #00b1b1;
    }
  }
  p {
    font-size: 1.6rem;
    line-height: 1.69;
    text-align: left;
    color: #736f7e;
  }
  ul {
    display: flex;
    list-style: none;
    justify-content: space-between;
    margin-top: 65px;

    ${media.lessThan("600px")`
      flex-wrap: wrap;
    `}

    li {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      img {
        width: 64px;
        height: 64px;
      }
      p {
        margin: auto;
        padding: 30px;
        text-align: center;
        b {
          color: #00b1b1;
        }
      }
    }
  }
`
