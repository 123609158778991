import Parser from 'html-react-parser';
import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import timelineText from './content';
import {
  Container,
  CustomScrollbar,
  ListTimeline,
  TimelineItem,
} from './styles';

export default function Timeline() {
  const { idioma } = useContext(LanguageContext);

  var title = [
    `<h2>Tudo o que somos hoje surgiu com muito trabalho, dedicação, compromisso,
                respeito e paixão por inovar. Conheça mais sobre a nossa história:</h2>
                <hr />`,

    `<h2>Everything we are today came with hard work, dedication, commitment, respect
                and passion for innovation. Learn more about our history:</h2>
                <hr />`,

    `<h2>Todo lo que somos hoy vino con trabajo duro, dedicación, compromiso, respeto
                y pasión por innovar. Conozca más sobre nuestra historia:</h2>
                <hr />`,
  ];

  return (
    <Container>
      {Parser(title[idioma])}
      <CustomScrollbar>
        <ListTimeline>
          {timelineText.map(({ text, icon }, index) => (
            <TimelineItem key={index}>
              <img src={icon} alt={text[idioma]} />
              <p>{text[idioma]}</p>
            </TimelineItem>
          ))}
        </ListTimeline>
      </CustomScrollbar>
    </Container>
  );
}
