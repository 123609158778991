import timeline1 from "../../../images/svg/timeline-01.svg"
import timeline2 from "../../../images/svg/timeline-02.svg"
import timeline3 from "../../../images/svg/timeline-03.svg"
import timeline4 from "../../../images/svg/timeline-04.svg"
import timeline5 from "../../../images/svg/timeline-05.svg"

export default [
  {
    text:
      ["A partir da venda da ideia para um investidor, iniciamos a atuação B2C como Vale Presente",
       "From selling the idea to an investor, we started the B2C performance as Vale Presente",
       "A partir de la venta de la idea a un inversor, comenzamos las operaciones B2C como Vale Presente"],
    icon: timeline1,
  },
  {
    text:
      ["A parceria entre a Caixa e a Vale Presente nos expandiu no mercado de incentivo e premiação e nos tornamos referência no segmento",
       "The partnership between Caixa and Vale Presente has expanded us into the incentive and award market and we have become a reference in the segment",
       "La asociación entre Caixa y Vale Presente nos ha expandido al mercado de incentivos y premios, y nos hemos convertido en una referencia en el segmento"],
    icon: timeline2,
  },
  {
    text:
      ["Conquistamos um novo mercado com a chegada da 99. Olhando além dos cartões pré-pagos, nossa participação no mundo dos “recebíveis” ganhou força. Então, passamos a ser uma holding de empresas de serviços financeiros, a Hub Prepaid",
       "We conquered a new market with the arrival of 99. Looking beyond prepaid cards, our participation in the world of “receivables” got stronger. So, we became a company holding of financial services, Hub Prepaid",
       "Conquistamos un nuevo mercado con la llegada de la 99. Más allá de las tarjetas prepagas, nuestra participación en el mundo de las «cuentas por cobrar» ganó fuerza. Entonces, nos convertimos en un holding de empresas de servicios financieros, Hub Prepaid "],
    icon: timeline3,
  },
  {
    text:
      ["Com a evolução de nossos serviços percebemos que estávamos deixando de ser uma holding de cartões pré-pagos e entramos de forma significativa nas contas digitais. Então, nos tornamos a Hub Fintech",
       "With the evolution of our services, we realized that we were no longer a prepaid card company holding and entered the digital accounts significantly. So, we became Hub Fintech",
       "Con la evolución de nuestros servicios, nos dimos cuenta de que ya no éramos un holding de tarjetas prepagas e ingresamos, de forma significativa, en las cuentas digitales. Entonces, nos convertimos en Hub Fintech"],
    icon: timeline4,
  },
  {
    text:
      ["A Hub Fintech continua no mercado de contas digitais, mas indo muito além disso. Atuamos de ponta a ponta no ecossistema financeiro como provedora de soluções banking as a service no modelo white label",
       "Hub Fintech remains in digital accounts market, but going well beyond that. We operate from end to end in the financial ecosystem as a provider of banking as a service solutions under the white label model",
       "Hub Fintech permanece en el mercado de cuentas digitales, pero va mucho más allá de eso. Operamos de punta a punta en el ecosistema financiero como proveedor de soluciones banking as a service bajo el modelo white label"],
    icon: timeline5,
  },
]
