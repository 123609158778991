import { Link } from 'gatsby';
import React, { useContext, useState } from 'react';
import { LanguageContext } from '../../context/LanguageContext';
import LogoHubfintechBranco from '../../images/svg/Logo-hubfintech-branco.svg';
import Menu from '../Menu';
import { Container, Header } from './styles';

export default function SomosHub({ scrollToForm }) {
  const { idioma } = useContext(LanguageContext);

  var title = [`Somos Hub`, `We are Hub`, `Somos Hub`];

  return (
    <Container>
      <Header>
        <Link to="/">
          <img src={LogoHubfintechBranco} alt="Hubfintech somos hub" />
        </Link>
        <h2>
          <b>{title[idioma]}</b> Fintech
          <hr />
        </h2>
      </Header>
      <Menu scrollToForm={scrollToForm} />
    </Container>
  );
}

/*
//BANDEIRAS PARA TRADUÇÃO

*/
