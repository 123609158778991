import React, { useContext } from 'react';
import { LanguageContext } from '../../../context/LanguageContext';
import { ButtonCareer, Container } from './styles';

export default function Careers() {
  const { idioma } = useContext(LanguageContext);

  var hub = [
    'Eu sou Hub e você?',
    "I'm Hub, and you?",
    'Yo soy Hub, ¿y usted?',
  ];
  var team = [
    'Venha fazer parte desse time incrível.',
    ' Come and be part of this incredible team.',
    'Venga a formar parte de este increíble equipo.',
  ];
  var vacancies = ['Nossas Vagas', 'Our Vacancies', 'Nuestras vacantes'];

  return (
    <Container>
      <div>
        <h2>{hub[idioma]}</h2>
        <span>{team[idioma]}</span>
      </div>
      <ButtonCareer
        onClick={() => window.open('https://jobs.kenoby.com/hubfintech')}
      >
        {vacancies[idioma]}
      </ButtonCareer>
    </Container>
  );
}
