import styled from "styled-components"
import bgCarreira from "../../../images/static/bg_carreira.png"
import media from "styled-media-query"
import { darken } from "polished"

export const Container = styled.div`
  display: flex;
  background-image: url(${bgCarreira});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 273px;
  padding: 8rem 18rem;
  justify-content: space-between;

  ${media.lessThan("1200px")`
    flex-direction: column;
    align-items:center;
    padding: 4rem 9rem;
  `}
  ${media.lessThan("800px")`
    padding: 3rem 6rem;
  `}

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${media.lessThan("1200px")`
        align-items: center;
    `}
    h2 {
      max-width: 397px;
      width: 100%;
      font-size: 4rem;
      font-weight: bold;
      text-align: left;
      color: #ffffff;
      ${media.lessThan("600px")`
        font-size: 3.3rem;
      `}
    }
    span {
      max-width: 557px;
      width: 100%;
      font-size: 3rem;
      text-align: left;
      color: #ffffff;
      ${media.lessThan("600px")`
        font-size: 2.5rem;
      `}
    }
    ${media.lessThan("800px")`
      h2{
        text-align: center;
      }
      span{
        text-align: center;
      }
    `}
  }
`

export const ButtonCareer = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  width: 258px;
  height: 74px;
  border-radius: 100px;
  background-color: #ff9d00;
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  transition: background 0.2s;
  ${media.lessThan("600px")`
    height: 50px;
    width: 240px;
  `}
    :hover {
    background: ${darken(0.1, "#ff9d00")};
  }
`
