import styled from "styled-components"
import media from "styled-media-query"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8rem 16rem;

  ${media.lessThan("800px")`
    padding: 5rem 8rem;
  `}

  ${media.lessThan("600px")`
    padding: 5rem;
  `}

  & > div:first-child {
    width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 100px;

    & svg {
      margin: auto 20px;
    }

    h2 {
      max-width: 317px;
      width: 100%;
      font-size: 4.8rem;
      font-weight: bold;
      line-height: 1.36;
      text-align: left;
      color: #00b1b1;
      ${media.lessThan("800px")`
        font-size:4rem;
        text-align: center;
      `}
    }
    p {
      max-width: 485px;
      width: 100%;
      font-size: 1.8rem;
      text-align: left;
      color: #707070;
      ${media.lessThan("800px")`
        font-size:1.6rem;
      `}
    }
    ${media.lessThan("1100px")`
      flex-direction: column;
      
      & svg {
        display: none;
      }
      
      p{
        text-align: center;
      }
    `}
  }
  
`
